import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

import "./App.scss";
import "./i18n/config";
import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader";
import Profile from "./pages/Profile";
import Resources from "./pages/Resources";
import Dataplanes from "./pages/Dataplanes";
import Database from "./pages/Database";
import DatabaseDetails from "./pages/Database/details";
import UI from "./pages/UI";
import Clusters from "./pages/Clusters";
import AccountsLayout from "./pages/Accounts";
import Simulators from "./pages/Simulators";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Permissions from "./pages/Permissions";
import Models from "./pages/Models";
import { setTokenFetcher } from "./services/api";

import "./App.scss"

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    error,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();


  useEffect(() => {
    // Initialize the application permissions
    isAuthenticated && getAccessTokenSilently();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Use this to pass the getAccessTokenSilently function to api.ts
  useEffect(() => {
    setTokenFetcher(() => getAccessTokenSilently());
  }, [getAccessTokenSilently, isAuthenticated]);

  if (error) {
    return (
      <div className="error-container">
        <div className="row text-center">
          <div className="error-item">Controlrooms</div>
          <div className="error-item">{error.message}</div>
          <Button
            color="primary"
            onClick={() => {
              logout({
                returnTo: window.location.origin,
              });
            }}
            style={{
              marginTop: "20px",
            }}
          >
            Take me to login.
          </Button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return <div className="App">{isAuthenticated ? <AppRoutes /> : <></>}</div>;
};

export default App;

const AppRoutes = () => {
  return (
    <Dashboard>
      <Routes>
        <Route path="/" element={<Resources />} />
        <Route path="/Dataplanes" element={<Dataplanes />} />
        <Route path="/Database" element={<Database />} />
        <Route path="/Database" element={<Database />} />
        <Route path="/Database/:dbName" element={<DatabaseDetails />} />
        <Route path="/Simulators" element={<Simulators />} />
        <Route path="/Accounts" element={<AccountsLayout />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/Roles" element={<Roles />} />
        <Route path="/Permissions" element={<Permissions />} />
        <Route path="/Models" element={<Models />} />
        <Route path="/Clusters" element={<Clusters />} />
        <Route path="/UI" element={<UI />} />
        <Route path="/Profile" element={<Profile />} />
      </Routes>
    </Dashboard>
  );
};
